import { Blocks, ClassifierLink, DataResult, Panel, TemplateType, ValueRequest, ValueResponse } from 'entities';
import contextStore from 'entities/context/contextStore';
import { getValuesBulk } from 'entities/dataValue/api';
import i18n from 'entities/localization/i18n';
import notificationsStore from 'entities/notification/notificationsStore';
import { Block, CardSettings, GridItemProps } from 'entities/panel/block';
import { Layout } from 'entities/panel/model/layout';
import userStore from 'entities/user/userStore';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { isMobile } from 'react-device-detect';
import { Layout as GridLayout } from 'react-grid-layout';
import { defined } from 'shared/lib/checks';
import { reviveDateTime } from 'shared/lib/dates';
import { Guid } from 'shared/lib/guid';
import { replaceURLParam } from 'shared/lib/url';
import { addPanel, getPanel, getPanels, getTemplatesPanel, removePanel, updatePanelTags } from './api';
import { createTemplatePanels } from './api/createTemplatePanels';
import { updatePanel } from './api/updatePanel';
import { updateBlock } from './block/api';
import { parseConfig } from './block/contents/card/lib';
import { panelURLParamName } from './config';
import { getLastUsedPanelId, setLastUsedPanelId } from './lib';
import { isEqual } from 'lodash';

class PanelsStore {
  //#region Load indicators

  /** Common panels data is loading */
  public isLoading = true;
  /** Current panel is loading */
  public isPanelLoading = true;

  //#endregion

  //#region All panels

  /** All panels */
  private _panels: Panel[] = [];
  /** Current panel */
  public current: Panel | null = null;
  /** Current panel layout */
  public layout: Layout | null = null;
  /** Editable panel layout for restore */
  private _layout: Layout | null = null;
  public initBlockContent: unknown = null;
  public initPanel: Panel | null = null;
  public isBlockContentDirty = false;

  /** Is panel editing */
  public isEditing = false;
  public key = Guid.create().toString();

  //#endregion

  //#region All blocks

  /** All blocks */
  public blocks: Block[] = [];
  /** Current editable block */
  public editableBlock?: Block;
  /** All blocks for restoration */
  private _blocks: Block[] = [];
  private _copiedBlockId: string | undefined;

  //#endregion

  //#region Panel data

  /** Cards data */
  public cardExternalData: Record<string, DataResult<ValueResponse>> | undefined;

  //#endregion

  //#region Ctor
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.current,
      () => {
        runInAction(() => {
          this.layout = this.current?.layout ?? null;
        });
      }
    );

    reaction(
      () => this.isEditing,
      () => {
        if (this.isEditing) {
          this._layout = this.layout;
          this._blocks = this.blocks;
        } else {
          this._layout = null;
          this._blocks = [];
          void this.loadData();
        }
        this.key = Guid.create().toString();
      }
    );

    reaction(
      () => this.editableBlock,
      () => {
        this.key = Guid.create().toString();
        if (!this.editableBlock && !this.isEditing) {
          void this.loadData();
        }
      }
    );

    reaction(
      () => this.editableBlock?.content,
      (value) => {
        this.isBlockContentDirty = isEqual(toJS(value), toJS(this.initBlockContent));
      }
    );
  }
  //#endregion

  //#region Panel

  /**
   * Filtered panels
   */
  public get panels() {
    return !isMobile ? this._panels : this._panels.filter((panel) => panel.layout.isMobile);
  }

  /**
   * Load current panel blocks data.
   */
  public async loadData() {
    const data = this.blocks.filter((block) => block.type === 'Card');
    const dataRecords: Record<string, ValueRequest> = {};

    data.forEach((card) => {
      const config = parseConfig(this.parseSettings<CardSettings>(card.content));

      if (config) {
        dataRecords[card.id] = { ...config, fillOnDate: true };
      }
    });

    if (Object.keys(dataRecords).length === 0) {
      return;
    }

    const values = await getValuesBulk(defined(contextStore.currentContextId), dataRecords);
    runInAction(() => {
      this.cardExternalData = values;
    });
  }

  public setPanels(panels: Panel[]) {
    runInAction(() => {
      this._panels = panels;
    });
  }

  /**
   * Load all context panels.
   * @param {number} contextId Context id
   */
  public async loadPanels(contextId: number) {
    try {
      runInAction(() => {
        this.isLoading = true;
        this._panels = [];
      });

      // Получение своих и общедоступных панелей, без личных панелей других пользователей.
      // Личная панель может быть получена администратором по запросу GET `.../${contextId}/panels/${panelId}`
      const panels = await getPanels(contextId);
      runInAction(() => {
        this._panels = panels;
      });

      const lastPanelLocalId = getLastUsedPanelId(contextId);

      if (lastPanelLocalId) {
        if (panels.find((panel) => panel.id === lastPanelLocalId)) {
          await this.changeCurrentPanel(contextId, lastPanelLocalId);
        } else {
          if (userStore.isAdministrator) {
            runInAction(() => {
              this.isPanelLoading = true;
            });
            try {
              const panel = await getPanel(contextId, lastPanelLocalId);
              runInAction(() => {
                this._panels = [...this._panels, panel];
                this.isPanelLoading = false;
              });
              this.setCurrentPanel(contextId, panel);
            } catch (ex) {
              await this.changeCurrentPanel(contextId, panels[0]?.id ?? null);
            }
          } else {
            await this.changeCurrentPanel(contextId, panels[0]?.id ?? null);
          }
        }
      } else {
        await this.changeCurrentPanel(contextId, panels[0]?.id ?? null);
      }
    } catch (ex) {
      notificationsStore.notify('error', i18n.strings.Errors.Panels.LoadError.Title, (ex as Error).message);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  public filteringTemplatesPanels(filterText: string, templatesPanels: TemplateType[], type?: 'Common' | 'User') {
    let filteredTemplatesPanels = [...templatesPanels];
    if (filterText.trim().length > 0 || type) {
      filteredTemplatesPanels = filteredTemplatesPanels.filter((template) => {
        const isFindText = defined(template.name).trim().toLowerCase().includes(filterText.trim().toLowerCase());
        if (type === 'User' && isFindText) {
          return template.userId === userStore.user?.id && isFindText;
        }
        if (type === 'Common' && isFindText) {
          return template.userId !== userStore.user?.id && isFindText;
        }
        return isFindText;
      });
    }

    return filteredTemplatesPanels;
  }

  public filteringGroups(filterText: string, templatePanelSelected: TemplateType) {
    let filteredGroups = [...(templatePanelSelected.items ?? [])];
    if (filterText.trim().length > 0) {
      filteredGroups = filteredGroups.map((group) => {
        return {
          ...group,
          panels: group.panels?.filter((panel) =>
            panel.name.trim().toLowerCase().includes(filterText.trim().toLowerCase())
          ),
        };
      });
    }

    return filteredGroups;
  }

  /**
   * Function that loads panel and sets as current.
   * @param contextId Context id
   * @param panelId Panel id
   */
  public async changeCurrentPanel(contextId: number, panelId: number | null) {
    if (this.current?.id === panelId) {
      return;
    }

    if (panelId) {
      runInAction(() => {
        this.isPanelLoading = true;
      });

      const panel = await getPanel(contextId, panelId);
      this.setCurrentPanel(contextId, panel);

      runInAction(() => {
        this.isPanelLoading = true;
      });
    } else {
      this.setCurrentPanel(contextId, null);
    }
  }

  /**
   * Update panel
   * @param contextId Context id
   * @param panelId Panel id
   */
  public async reloadCurrentPanel(contextId: number, panelId: number) {
    if (this.current?.id !== panelId) {
      return;
    }

    runInAction(() => {
      this.isPanelLoading = true;
    });

    const panel = await getPanel(contextId, panelId);
    this.setCurrentPanel(contextId, panel);

    runInAction(() => {
      this.isPanelLoading = true;
    });
  }

  /**
   * Sets edit mode for panel
   * @param value Is panel editing
   */
  public setEditMode(value: boolean) {
    runInAction(() => {
      this.isEditing = value;
    });
  }

  /**
   * Function that adds panel and saves it to backend.
   * @param {number} contextId Context id
   * @param {Panel} panel Panel object
   */
  public async addPanel(contextId: number, data: Panel) {
    const panel = await addPanel(contextId, data);

    runInAction(() => {
      this._panels = [...this._panels, panel];
    });

    this.setCurrentPanel(contextId, panel);
  }

  /**
   * Function that updates panel parameters and saves it to backend.
   * @param contextId Context id
   * @param panel Panel object
   */
  public async updatePanel(contextId: number, isChangeCurrentPanel: boolean, panel?: Panel) {
    if (!this.current) {
      return;
    }

    try {
      const panelToUpdate = panel ?? { ...this.current, blocks: this.blocks };
      await updatePanel(contextId, panelToUpdate);
      const panels = [...this._panels];
      panels.splice(
        panels.findIndex((pnl) => pnl.id === panelToUpdate.id),
        1,
        panelToUpdate
      );
      runInAction(() => {
        this._panels = panels;
        if (isChangeCurrentPanel) {
          this.current = panelToUpdate;
        }
      });
      this.setEditMode(false);
    } catch (ex) {
      notificationsStore.notify('error', i18n.strings.Errors.Panels.UpdateError.Title, (ex as Error).message);
    }
  }

  public async updatePanelTags(contextId: number, panelId: number, tags: ClassifierLink[]) {
    await updatePanelTags(contextId, panelId, tags);
  }

  /**
   * Function that removes panel and sends updates to backend.
   * @param {number} contextId Context id
   * @param {number} id Panel id
   */
  public async removePanel(contextId: number, id: number) {
    await removePanel(contextId, id);

    runInAction(() => {
      const panels = this._panels;
      panels.splice(
        this._panels.findIndex((panel) => panel.id === id),
        1
      );
      this._panels = panels;
      this.setCurrentPanel(contextId, panels[0] ?? null);
    });
  }

  /**
   * Function that updates panel layout params
   * @param {Layout} layout Panel layout
   */
  public updateLayout(layout: Layout) {
    runInAction(() => {
      this.layout = layout;
    });
  }

  /**
   * Function that updates panel blocks position when layout is updated.
   * @param layouts Layout items
   */
  public updateLayoutItems(layouts: GridLayout[]) {
    const blocks = [...this.blocks];
    layouts.forEach((layout) => {
      const index = blocks.findIndex((block) => block.id === layout.i);

      if (index === -1) {
        return;
      }

      blocks[index] = {
        ...defined(blocks[index]),
        x: layout.x,
        y: layout.y,
        height: layout.h,
        width: layout.w,
      } as Block;
    });
    runInAction(() => {
      this.blocks = blocks;
    });

    if (this._copiedBlockId && !this.isEditing) {
      void updatePanel(
        defined(contextStore.currentContextId),
        defined({ ...this.current, blocks: this.blocks } as Panel)
      );

      runInAction(() => {
        this._copiedBlockId = undefined;
      });
    }
  }

  /**
   * Function that cancels panel editing and restores initial  values.
   */
  public cancelEditing() {
    runInAction(() => {
      this.layout = this._layout;
      this.blocks = this._blocks;
      this.isEditing = false;
    });
  }

  /**
   * Function that sets panel as current and updates URL accordingly.
   * @param {number} contextId Context id
   * @param {Panel | null} panel Panel object
   */
  private setCurrentPanel(contextId: number, panel: Panel | null) {
    runInAction(() => {
      this.current = panel;
      this.blocks = (panel?.blocks ?? []).map((block) => {
        return {
          ...block,
          content: this.parseSettings(block.content),
        } as Block;
      });
      this._blocks = this.blocks;
      replaceURLParam(panelURLParamName, panel?.id?.toString() ?? '');
    });

    setLastUsedPanelId(contextId, this.current?.id ?? null);
    void this.loadData();
  }
  //#endregion

  //#region Block

  /**
   * Current editable block id
   */
  private get _id() {
    return this.editableBlock?.id;
  }

  /**
   * Function that adds block to panel.
   * @param type Block type
   * @param x Block x position
   * @param y Block y position
   * @param width Block width
   * @param height Block height
   * @param content Block configuration
   */
  public addBlock(type: Blocks, x = 0, y = 0, width?: number, height?: number, content: unknown = {}) {
    runInAction(() => {
      this.blocks.unshift({
        id: Guid.create().toString(),
        type,
        x,
        y,
        width,
        height,
        content,
      } as Block);
    });
  }

  public setLayout(layout: Layout) {
    runInAction(() => {
      this.layout = layout;
    });
  }

  /**
   * Function that calculate panel height.
   * Used for copy block to end.
   * @param blocks Panel blocks
   * @param offset Base offset
   */
  private panelHeight(blocks: Block[], offset = 10) {
    return blocks.reduce((acc, current) => (acc > current.y ? acc : current.y), offset);
  }

  /**
   * Function that copies block on some panel.
   * @param panelId Panel to copy id
   * @param block Block
   * @param saveImmediately Save to server immediately (for non panel editing mode)
   */
  public async copyBlock(panelId: number, block: Block) {
    const id = Guid.create().toString();

    const panel = this._panels.find((panel) => panel.id === panelId);

    try {
      if (panelId === this.current?.id) {
        runInAction(() => {
          this.blocks = [
            {
              ...JSON.parse(JSON.stringify(block)),
              id,
              x: 0,
              y: this.panelHeight(this.blocks) + block.height,
            } as Block,
            ...this.blocks,
          ];
          if (this.cardExternalData?.[block.id]) {
            this.cardExternalData[id] = defined(this.cardExternalData[block.id]);
          }
        });

        if (!this.isEditing) {
          runInAction(() => {
            this._copiedBlockId = id;
          });
        }
      } else {
        const { blocks } = await getPanel(defined(contextStore.currentContextId), panelId);

        if (!panel) {
          return;
        }

        runInAction(() => {
          panel.blocks = blocks;
        });

        panel.blocks?.unshift({
          ...block,
          id,
          x: 0,
          y: this.panelHeight(panel.blocks) + block.height,
        } as Block);
      }
      notificationsStore.notify(
        'success',
        i18n.strings.Features.Panels.CopyInfoblockSuccess(panel?.name ?? ''),
        undefined,
        false,
        true,
        false
      );
    } catch (error) {
      if (error instanceof Error) {
        notificationsStore.notify(
          'error',
          i18n.strings.Errors.Panels.Blocks.CopyInfoblockError(panel?.name ?? ''),
          error.message
        );
        throw new Error(error.message);
      }
    }
  }

  /**
   * Function that removes block from panel.
   * @param blockId Block id
   */
  public removeBlock(blockId: string) {
    const blocks = [...this.blocks];
    const blockIndex = blocks.findIndex((block) => block.id === blockId);

    if (blockIndex === -1) {
      return;
    }

    blocks.splice(blockIndex, 1);

    runInAction(() => {
      this.blocks = blocks;
    });
  }

  /**
   * Function that gets block config typed.
   * @param id Block id
   * @returns Block config
   */
  public getBlockConfig<T>(id: string) {
    if (this.isBlockEditing(id)) {
      return this.parseSettings<T>(defined(this.editableBlock).content);
    } else {
      return this.parseSettings<T>(this.blocks.find((block) => block.id === id)?.content);
    }
  }

  /**
   * Function that gets editable block config.
   */
  public get editableBlockContent(): unknown {
    switch (this.editableBlock?.type) {
      case 'Card': {
        return this.parseSettings(this.editableBlock.content);
      }
      case 'Weather': {
        return this.parseSettings(this.editableBlock.content);
      }
      default: {
        return this.editableBlock?.content;
      }
    }
  }

  /**
   * Function that gets block config typed.
   * @param settings Block settings as unknown
   * @returns Block settings typed
   */
  public parseSettings<T>(settings: unknown) {
    if (typeof settings === 'string') {
      return JSON.parse(settings, reviveDateTime) as T;
    } else {
      return settings as T;
    }
  }

  /**
   * Function that updates block position and size.
   * @param blockId Block id
   * @param blockProps Block position and size params
   */
  public updateBlockDimensions(blockId: string, blockProps: GridItemProps) {
    const blocks = [...this.blocks];
    const blockIndex = blocks.findIndex((block) => block.id === blockId);

    if (blockIndex === -1) {
      return;
    }

    const block = {
      ...blocks[blockIndex],
      x: blockProps.x,
      y: blockProps.y,
      height: blockProps.h,
      width: blockProps.w,
    } as Block;

    blocks.splice(blockIndex, 1, block);

    runInAction(() => {
      this.blocks = blocks;
    });
  }

  /**
   * Function that updates block config.
   * @param contextId Context id
   * @param blockId Block id
   * @param content Block config
   */
  public async updateBlockSettings(contextId: number, blockId: string, content: unknown) {
    const blocks = [...this.blocks];
    const blockIndex = blocks.findIndex((block) => block.id === blockId);

    if (blockIndex === -1) {
      return;
    }

    const block = defined(blocks[blockIndex]);
    block.content = content;

    try {
      const hasPermissionAndTags = userStore.role?.abilities.some((ability) => {
        if (ability.ability === 'DoEverything') {
          return true;
        }

        if (
          ability.ability === 'EditPanelsClassifiedStarting' ||
          ability.ability === 'EditPanelTagsClassifiedStarting'
        ) {
          return this.current?.tags?.some((tag) => ability.classifiers?.includes(tag));
        }

        return false;
      });
      if (!this.isEditing && (hasPermissionAndTags || this.current?.userId)) {
        await updateBlock(contextId, defined(this.current?.id), blockId, block);
      }

      blocks.splice(blockIndex, 1, block);

      runInAction(() => {
        this.blocks = blocks;
      });
    } catch (ex) {
      notificationsStore.notify('error', i18n.strings.Errors.Panels.Blocks.UpdateError, (ex as Error).message);
    }
  }

  /**
   * Function that checks if block with specific id is editing at the moment or some block is editing if id is not provided.
   * @param id Block id
   * @returns If block is editing or some block editing
   */
  public isBlockEditing(id?: string) {
    if (!id) {
      return !!this._id;
    }

    return this._id === id;
  }

  /**
   * Function that updates current editable block config.
   * @param content Block config
   */
  public updateEditableContent(content: unknown) {
    if (!this.editableBlock) {
      return;
    }
    runInAction(() => {
      defined(this.editableBlock).content = content;
    });
  }

  /**
   * Function that puts block to edit state.
   * @param id Block id
   */
  public startBlockEditing(id: string) {
    const data = { ...defined(this.blocks.find((block) => block.id === id)) } as Block;
    data.content = this.parseSettings(data.content);

    runInAction(() => {
      this.editableBlock = data;
      this.initBlockContent = data.content;
    });
  }

  public async loadTempPanel() {
    const templatePanelsHeader = await getTemplatesPanel(defined(contextStore.currentContextId));
    if (templatePanelsHeader.length === 0) {
      const newTemplate: TemplateType = {
        roles: [defined(userStore.role).id],
        id: undefined,
        name: 'Шаблон 1',
        items: [],
        userId: userStore.user?.id,
      };
      await createTemplatePanels(defined(contextStore.currentContextId), newTemplate);
    }
  }

  /**
   * Function that cancels block edit state.
   */
  public cancelBlockEditing() {
    runInAction(() => {
      this.editableBlock = undefined;
      this.initBlockContent = null;
      this.isBlockContentDirty = false;
    });
  }

  /**
   * Function that saves editable block config.
   * @param contextId Context id
   */
  public async saveBlockContent(contextId: number) {
    await this.updateBlockSettings(contextId, defined(this._id), this.editableBlockContent);
    this.cancelBlockEditing();
  }

  //#endregion
}

export default new PanelsStore();
